// required to get $orange variable
@import "~bootstrap/scss/functions"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/bootstrap";

:root, [data-bs-theme=light] {
  --bs-blue: #1173B0;
  --bs-body-color: #48494B;
  --bs-body-bg: #f5f5f5;
}

:root, [data-bs-theme=dark] {
  --bs-body-color: #fff;
  --bs-body-bg: #0E1023;
}

:root {
  --bs-green-ortus: #8FC73E;
  --bs-red-theme: #C8193C;


  /* Light mode variables */
  --light-highlight: #C8193C;
  --light-secondary: #1D3D69;

  --light-link-color: #1173B0;
  --light-link-hover-color: #1D3D69;
  --light-news-bg: #E0F0F7;
  --light-link-footer-color: #757575;

  /* Dark mode variables */
  --dark-highlight: #f08d49;
  --dark-blue:#369BC2; 
  --dark-primary: #00EBFF;
  --dark-secondary: #151729;

  --dark-link-color: #fff;  
  --dark-link-footer-color: #A1A1A1;
}

/* Override Bootstrap's light mode styles */
@include color-mode(light) {

  .nav-link {
    color: var(--light-link-color);
  }
  .nav-link:hover {
    color: var(--light-link-hover-color);
  }

  .nav-footer-link {
    color: var(--light-link-footer-color);
  }
  .nav-footer-link:hover {
    color: var(--light-link-color);
  }

  .text-success {
    color: var(--bs-green-ortus) !important;
  }  

  .text-primary {
    color: var(--light-link-color) !important;
  }

  .bg-light {
    background-color: #fff;
  }
  
  .text-secondary {
    color: var(--light-secondary) !important;
  }
  
  .btn-primary {
    background-color: var(--light-link-color);
    border-color: var(--light-link-color);
  }
  .btn-primary:hover {
    background-color: var(--light-link-hover-color);
    border-color: var(--light-link-hover-color);
  }

  .btn-outline-primary {
    color: var(--light-link-color);
    border-color: var(--light-link-color);
  }
  .btn-outline-primary:hover {
    color: #fff;
    border-color: var(--light-link-hover-color);
    background-color: var(--light-link-hover-color);
  }

  .btn:focus-visible {
    color: #fff;
    border-color: var(--light-link-hover-color);
    background-color: var(--light-link-hover-color);
  }

  .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: #fff;
    background-color: var(--light-link-hover-color);
    border-color: var(--light-link-hover-color);
  }

  .btn-danger{
    background-color: var(--bs-red-theme);
    border-color: var(--bs-red-theme);
  }

  .card {
    background-color: #fff;
  }

  #v-pills-tab {
    .nav-link {
      color: var(--light-link-color) !important;
    }
    .nav-link:hover {
      color: var(--light-link-hover-color) !important;
    }

    .active{
      color: var(--light-secondary) !important;
      border: 5px solid var(--light-link-color) !important;

      background-color: transparent !important;
      border-radius: 0px !important;
      border-right: 0px !important;
      border-top: 0px !important;
      border-bottom: 0px !important;
      padding-left: 9px !important;
    }
  }

  /* Custom styles */
  .bg-navbar-custom {
    background-color: rgb( 255 255 255 / 95%);
  }

  .bg-news, .bg-products{
    background-color: var(--light-news-bg);
  }

  .shadow-xl:hover {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
  }

  .code-highlighter{
    color: var(--light-highlight);
  }

}

/* Override Bootstrap's dark mode styles */
@include color-mode(dark) {
  
  .nav-link {
    color: var(--dark-link-color);
  }
  .nav-link:hover {
    color: var(--dark-blue);
  }
  

  .nav-footer-link {
    color: var(--dark-link-footer-color);
  }
  .nav-footer-link:hover {
    color: var(--dark-blue);
  }

  .text-success {
    color: var(--bs-green-ortus) !important;
  }

  .text-primary {
    color: var(--dark-primary) !important;
  }

  .text-secondary {
    color: #fff !important;
  }

  .bg-light {
    background-color: #EBEBEB;
  }
  
  .btn-primary {
    color: var(--dark-secondary);
    background-color: var(--dark-primary);
    border-color: var(--dark-primary);
  }
  .btn-primary:hover {
    color: #fff;
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
  }
  
  .btn-info{
    color: #fff;
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
  }

  .btn-outline-primary {
    color: var(--dark-primary);
    border-color: var(--dark-primary);
  }
  .btn-outline-primary:hover {
    color: #fff;
    border-color: var(--dark-blue);
    background-color: var(--dark-blue);
  }

  .btn-outline-info{
    color: var(--dark-primary);
    border-color: var(--dark-primary);
  }
  .btn-outline-info:hover{
    color: #fff;
    border-color: var(--dark-blue) !important;
    background-color: var(--dark-blue) !important;
  }

  .btn:focus-visible {
    color: #fff;
    border-color: var(--dark-blue);
    background-color: var(--dark-blue);
  }

  .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: #fff;
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
  }

  .btn-danger{
    background-color: var(--bs-red-theme);
    border-color: var(--bs-red-theme);
  }

  #v-pills-tab {
    .nav-link {
      color: var(--dark-primary) !important;
    }
    .nav-link:hover {
      color: var(--dark-blue) !important;
    }

    .active{
      color: #fff !important;
      border: 5px solid var(--dark-primary) !important;

      background-color: transparent !important;
      border-radius: 0px !important;
      border-right: 0px !important;
      border-top: 0px !important;
      border-bottom: 0px !important;
      padding-left: 9px !important;
    }
  }

  /* Custom styles */

  .bg-navbar-custom {
    background-color: rgb( 14 16 35 / 95%);
  }

  .bg-news, .card {
    background-color: var(--dark-secondary);
  }

  .bg-products {
    background-color: #fff;
  }

  .shadow-xl:hover {
    background-color: #fff !important;
  }

  .code-highlighter{
    color: var(--dark-highlight);
  }
}